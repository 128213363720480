import React, { useState, useEffect } from "react";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ScrollToTop.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [visible]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible);
  }

  return (
    <button
      className={cx(
        `fixed block rounded-[12px] p-2 w-14 h-14 text-4xl hover:text-5xl md:bottom-10 md:right-10 bottom-4 right-4 ${
          visible ? "opacity-100" : "opacity-0 pointer-events-none"
        } ${
          styles.holo
        } z-40 flex justify-center items-center drop-shadow-card translate-z-0 transition-all ease-in-out delay-50 duration-300`
      )}
    >
      <FontAwesomeIcon
        icon={faChevronUp}
        onClick={scrollToTop}
        className='text-white h-full w-full'
      />
    </button>
  );
};

export default ScrollToTop;
